import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { ServiceTile, ServiceTiles } from 'modules/Bundles/ServiceTiles';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import SavingsContainer from 'redux/modules/Bundles/Savings/container';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import {
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
} from 'redux/modules/Bundles/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import { price as pricePropType } from 'app/constants/propTypes';
import SavingsTip from 'modules/Bundles/SavingsTip';
import LegalInformation from 'modules/Bundles/LegalInformation';
import { EVENTS } from 'app/lib/analytics/constants';
import { ENTITIES } from 'app/lib/analytics/entityContexts';
import EPGAlert from 'modules/Shared/EPGAlert';

const Bundles = ({
  propositionFetch,
  propositionRulesFetch,
  hasSelectedAService,
  selectBundle,
  selectedServiceIds,
  products,
  addService,
  removeService,
  loadingProposition,
  activeDiscounts,
  savingsTip,
  isTenant,
  productSelectionWarning,
  confirmLoading,
  bundleRestrictionsRequest,
  restrictionsLoading,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const analytics = useTracking();
  const appServicesSelectedState = useEvents(
    EVENTS.APPLICATION_SERVICES_SELECTED
  );

  const isHosted = Boolean(useSelector(PartnerLoginApi.getHostedId));
  const coreServicesState = useEvents(ENTITIES.CORE_SERVICES);

  const handleOnChangeAnalytics = (service, serviceId) => {
    const event = service.selected
      ? EVENTS.BUNDLES_SERVICE_REMOVED
      : EVENTS.BUNDLES_SERVICE_SELECTED;

    analytics.track(event, {
      service_id: serviceId === PRODUCT_MOBILE_2 ? PRODUCT_MOBILE : serviceId,
      ...coreServicesState,
    });
  };

  const handleOnChange = (service, serviceId) => {
    handleOnChangeAnalytics(service, serviceId);

    if (service.selected) {
      return removeService(serviceId);
    }

    return addService(serviceId);
  };

  useEffect(() => {
    propositionRulesFetch();
    bundleRestrictionsRequest();
  }, [propositionRulesFetch, bundleRestrictionsRequest]);

  useEffect(() => {
    propositionFetch();
  }, [propositionFetch]);

  return (
    <Fragment>
      <SectionCentered classes={{ root: classes.bodyRoot }}>
        <Typography variant="h3" className={classes.heading}>
          Guaranteed savings on top of our market-leading services
        </Typography>
        <Typography>
          The energy price below is based on the average usage for your region.
          You'll be able to review and customise options for your chosen
          services later.
        </Typography>
        <EPGAlert classes={{ root: classes.alertBanner }} />
        <ServiceTiles>
          {!isDesktop && <SavingsTip savingsTip={savingsTip} />}
          {products.map((service) => (
            <ServiceTile
              onChange={(serviceId) => handleOnChange(service, serviceId)}
              service={service}
              key={service.id}
              loading={loadingProposition || restrictionsLoading}
              discountPrice={activeDiscounts[service.id]}
              isTenant={isTenant}
            />
          ))}
          {isDesktop && <SavingsTip savingsTip={savingsTip} />}
        </ServiceTiles>
        <SavingsContainer />
      </SectionCentered>
      <LegalInformation />
      <NavigationPane
        helpCtaEnabled={!isHosted}
        back
        next
        nextLabel={
          <>
            Next
            {confirmLoading && (
              <CircularProgress
                size={16}
                thickness={5}
                className={classes.buttonLoading}
              />
            )}
          </>
        }
        nextHandler={() => {
          selectBundle(selectedServiceIds);

          analytics.track(
            EVENTS.APPLICATION_SERVICES_SELECTED,
            appServicesSelectedState
          );
        }}
        nextDisabled={
          !hasSelectedAService ||
          productSelectionWarning?.error ||
          confirmLoading
        }
      >
        <Typography variant="caption" className={classes.productSelectionError}>
          {productSelectionWarning && productSelectionWarning.message}
        </Typography>
      </NavigationPane>
    </Fragment>
  );
};
Bundles.propTypes = {
  propositionFetch: PropTypes.func.isRequired,
  propositionRulesFetch: PropTypes.func.isRequired,
  hasSelectedAService: PropTypes.bool.isRequired,
  selectBundle: PropTypes.func.isRequired,
  selectedServiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  activeDiscounts: PropTypes.objectOf(pricePropType).isRequired,
  addService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  loadingProposition: PropTypes.bool.isRequired,
  savingsTip: PropTypes.string.isRequired,
  isTenant: PropTypes.bool.isRequired,
  deposit: PropTypes.shape({
    reason: PropTypes.string.isRequired,
    amount: pricePropType,
  }),
  productSelectionWarning: PropTypes.shape({
    message: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
  }),
  confirmLoading: PropTypes.bool.isRequired,
  bundleRestrictionsRequest: PropTypes.func.isRequired,
  restrictionsLoading: PropTypes.bool.isRequired,
};

export default Bundles;
